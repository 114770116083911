

.Home, .links, .Name {
    padding: 0 10%;
    font-size: 20px;
    text-align: center;
}

.Name {
 font-size: 60px;
 /* margin: 0 !important; */
 margin-top: 100px !important;
 font-family: Hind;
 font-display:swap;
}

.top {
    height: 235px;
}


.links {
    font: 25px;
    margin: 0 !important;

}

.links > a{
    padding: 0 10px;
}

.Projects{
    text-align: left;
    padding: 110px 0 0;
    /* margin-top: 20px; */
    /* top: -200px; */
}

.Project{
    display: grid;
    /* padding: 5% 15%; */
    align-items: center;
    padding: 2vh 1vw !important;
}

.Projects-Details {
    width: 80%;
    padding-left: 10%;
}

/* .Project.L > .Project-Picture {
    padding-right: 15%;
} */

.Project.R > .Project-Picture {
    padding-left: 15%;
}

.Project.L{
    grid-template-columns: .75fr 1.5fr;
}

.Project.R{
    grid-template-columns: 1.5fr .75fr;
}

.Title{
    font-size: 20px !important;
}

.Project-Description{
    font-size: 15px !important;
}