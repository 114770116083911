@import url('https://fonts.googleapis.com/css?family=Hind');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.Home, .about {
  padding: 75px 150px;
}

a {
  text-decoration: none;
  color: inherit;
}

p, li {
  font-family: Open Sans;
}

p, h1, h2, li {
  color: #6B6D76;
}

h1{
  margin: 0 !important;
}

.contact > h1, .Projects-Header {
  font-family: Hind;
  font-display:swap;
  font-size: 40px;
}

.contact{
  padding: 75px 0 0;
}

@media screen and (max-width: 850px) {
  .Home, .about {
    padding: 50px;
  }

  .Name {
    font-size: 7vw;
  }

  .links {
    font-size: 2vw;
  }

  .contact > h1, .Projects-Header{
    font-size: 5vw;
  }

  #myTopnav {display: none;}
  .topnav > a {
      display: block !important;
      text-align: center;
      padding: 0px 0px 20px;
      font-size: 17px;
  }
  .ico {
    display: inline-block;
  }
  .topnav.responsive {
    display: block !important;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
}