/* .about {
    padding: 75px 150px;
} */

img {
    width: 100%;
}

.info {
    display: grid;
    /* padding: 5% 15%; */
    grid-template-columns: .65fr 1.5fr;
    align-items: center;
    padding: 0 10px !important;
}

h1 {
    font-size: 2vw;
}

.details{
    padding: 0% 10%;
    font-size: 1.5vw;
}

.Icons {
    margin: 45px 0 0;
    text-align: center;
    font-size: 30px;
}


/* .icon{
    width: 150%;
} */