@import url("https://fonts.googleapis.com/css?family=Shrikhand");

.App-logo {
  font-family: Shrikhand !important;
  font-size: 75px !important;
  margin-left: 45px !important;
  /* color: #E3B7A6 !important; */
  color: #fb6d8e !important;
  font-display:swap;
}

.topnav > a,
.dropdown > .drop,
.dropdown-content {
  margin: 0px 30px;
  font-size: 15px;
  font-family: Open Sans;
  font-weight: 600;
  color: #6b6d76;
  position: relative;
  font-display:swap;
}

.topnav > a:hover,
.dropdown-content > a:hover,
.dropdown:hover {
  text-decoration: underline;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.icon {
  margin: 0 20px;
  color: #6b6d76;
}

.icons {
  float: right;
  margin: 60px 45px 0px 0px;
}

.ico {
  padding: 33px 45px 0 0;
  color: #6b6d76;
  font-size: 35px;
  text-decoration: none;
  float: right;
  display: none;
}

.navbar {
  background-color: #f8f9fa;
}
