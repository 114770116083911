footer {
    /* text-align: center; */
    /* padding: 40px 0; */
    /* display: inline; */
    padding-top: 25px;
    overflow: hidden;
    background-color: #F8f9fa;
    /* margin-bottom: 40px; */
}

.bottom-nav{
    float: left;
    /* text-align: left; */
    padding-left: 30px;
}

.copyright{
    /* text-align: right; */
    float: right;
    padding-right: 30px;
}

/* hr {
    border: 3px solid #F8F9FA;
} */