@import url(https://fonts.googleapis.com/css?family=Shrikhand);
@import url(https://fonts.googleapis.com/css?family=Hind);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display:swap;
}

.App-logo {
  font-family: Shrikhand !important;
  font-size: 75px !important;
  margin-left: 45px !important;
  /* color: #E3B7A6 !important; */
  color: #fb6d8e !important;
  font-display:swap;
}

.topnav > a,
.dropdown > .drop,
.dropdown-content {
  margin: 0px 30px;
  font-size: 15px;
  font-family: Open Sans;
  font-weight: 600;
  color: #6b6d76;
  position: relative;
  font-display:swap;
}

.topnav > a:hover,
.dropdown-content > a:hover,
.dropdown:hover {
  text-decoration: underline;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.icon {
  margin: 0 20px;
  color: #6b6d76;
}

.icons {
  float: right;
  margin: 60px 45px 0px 0px;
}

.ico {
  padding: 33px 45px 0 0;
  color: #6b6d76;
  font-size: 35px;
  text-decoration: none;
  float: right;
  display: none;
}

.navbar {
  background-color: #f8f9fa;
}

footer {
    /* text-align: center; */
    /* padding: 40px 0; */
    /* display: inline; */
    padding-top: 25px;
    overflow: hidden;
    background-color: #F8f9fa;
    /* margin-bottom: 40px; */
}

.bottom-nav{
    float: left;
    /* text-align: left; */
    padding-left: 30px;
}

.copyright{
    /* text-align: right; */
    float: right;
    padding-right: 30px;
}

/* hr {
    border: 3px solid #F8F9FA;
} */


.Home, .links, .Name {
    padding: 0 10%;
    font-size: 20px;
    text-align: center;
}

.Name {
 font-size: 60px;
 /* margin: 0 !important; */
 margin-top: 100px !important;
 font-family: Hind;
 font-display:swap;
}

.top {
    height: 235px;
}


.links {
    font: 25px;
    margin: 0 !important;

}

.links > a{
    padding: 0 10px;
}

.Projects{
    text-align: left;
    padding: 110px 0 0;
    /* margin-top: 20px; */
    /* top: -200px; */
}

.Project{
    display: grid;
    /* padding: 5% 15%; */
    align-items: center;
    padding: 2vh 1vw !important;
}

.Projects-Details {
    width: 80%;
    padding-left: 10%;
}

/* .Project.L > .Project-Picture {
    padding-right: 15%;
} */

.Project.R > .Project-Picture {
    padding-left: 15%;
}

.Project.L{
    grid-template-columns: .75fr 1.5fr;
}

.Project.R{
    grid-template-columns: 1.5fr .75fr;
}

.Title{
    font-size: 20px !important;
}

.Project-Description{
    font-size: 15px !important;
}
/* .about {
    padding: 75px 150px;
} */

img {
    width: 100%;
}

.info {
    display: grid;
    /* padding: 5% 15%; */
    grid-template-columns: .65fr 1.5fr;
    align-items: center;
    padding: 0 10px !important;
}

h1 {
    font-size: 2vw;
}

.details{
    padding: 0% 10%;
    font-size: 1.5vw;
}

.Icons {
    margin: 45px 0 0;
    text-align: center;
    font-size: 30px;
}


/* .icon{
    width: 150%;
} */
.Home, .about {
  padding: 75px 150px;
}

a {
  text-decoration: none;
  color: inherit;
}

p, li {
  font-family: Open Sans;
}

p, h1, h2, li {
  color: #6B6D76;
}

h1{
  margin: 0 !important;
}

.contact > h1, .Projects-Header {
  font-family: Hind;
  font-display:swap;
  font-size: 40px;
}

.contact{
  padding: 75px 0 0;
}

@media screen and (max-width: 850px) {
  .Home, .about {
    padding: 50px;
  }

  .Name {
    font-size: 7vw;
  }

  .links {
    font-size: 2vw;
  }

  .contact > h1, .Projects-Header{
    font-size: 5vw;
  }

  #myTopnav {display: none;}
  .topnav > a {
      display: block !important;
      text-align: center;
      padding: 0px 0px 20px;
      font-size: 17px;
  }
  .ico {
    display: inline-block;
  }
  .topnav.responsive {
    display: block !important;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
}
